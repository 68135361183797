import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";

import FontAwesomeIcon from "@/plugins/font-awesome";
import "@/plugins/amplify";

import NormalLayout from "@/components/NormalLayout.vue";
import EmptyLayout from "@/components/EmptyLayout.vue";

import "@/scss/app.scss";

createApp(App)
  .use(store)
  .use(router)
  .component("fa", FontAwesomeIcon)
  .component("normal-layout", NormalLayout)
  .component("empty-layout", EmptyLayout)
  .mount("#app");
