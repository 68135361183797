
import { defineComponent } from "vue";
import moment from "moment";

import Advertisement from "@/models/Advertisement";

interface AdvertisementDetailState {
  is_loading: boolean;

  advertisement: Advertisement;
}

export default defineComponent({
  data() {
    return {
      is_loading: false,

      advertisement: {} as Advertisement,
    } as AdvertisementDetailState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    formatAge(age: string) {
      if (age == "0-19") {
        return "0~19歳";
      } else if (age == "20-29") {
        return "20~29歳";
      } else if (age == "30-29") {
        return "30~39歳";
      } else if (age == "40-29") {
        return "40~49歳";
      } else if (age == "50-29") {
        return "50~59歳";
      } else if (age == "60-") {
        return "60歳~";
      }

      return "";
    },

    refresh() {
      this.is_loading = true;

      const id = this.$route.params.advertisement_id as string;
      console.log(`AdvertisementDetail.refresh(${id})`);

      setTimeout(async () => {
        try {
          console.log(id);
          this.advertisement = await Advertisement.get(id);
        } finally {
          this.is_loading = true;
        }
      });
    },
  },
});
