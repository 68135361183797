import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: "card",
  style: {"border":"none"}
}
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_authenticator = _resolveComponent("authenticator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createVNode(_component_authenticator, { "hide-sign-up": true })
        ])
      ])
    ])
  ]))
}