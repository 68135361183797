import { Auth } from "aws-amplify";
import axios from "axios";

import moment from "moment";

interface AdvertisementJson {
  id: string;
  status: string;
  title: string;
  video_url: string;
  thumbnail_url: string | null;
  transcoded_video_url: string | null;
  available_from: Date | null;
  available_to: Date | null;
  ages: string[];
  view_count: number;
  view_seconds: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface AdvertisementSearchResult {
  advertisements: Advertisement[];
  last_evaluated_key: string | null;
}

export default class Advertisement {
  id: string;
  status: string;
  title: string;
  video_url: string;
  thumbnail_url: string | null;
  transcoded_video_url: string | null;
  available_from: Date | null;
  available_to: Date | null;
  ages: string[];
  view_count: number;
  view_seconds: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  get thumbnail_url_v(): string {
    if (this.thumbnail_url) {
      return this.thumbnail_url;
    }

    return "/images/video.png";
  }

  constructor(
    id: string,
    status: string,
    title: string,
    video_url: string,
    thumbnail_url: string | null,
    transcoded_video_url: string | null,
    available_from: Date | null,
    available_to: Date | null,
    ages: string[],
    view_count: number,
    view_seconds: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date | null
  ) {
    this.id = id;
    this.status = status;
    this.title = title;
    this.video_url = video_url;
    this.thumbnail_url = thumbnail_url;
    this.transcoded_video_url = transcoded_video_url;
    this.available_from = available_from;
    this.available_to = available_to;
    this.ages = ages;
    this.view_count = view_count;
    this.view_seconds = view_seconds;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
  }

  static async get(id: string): Promise<Advertisement> {
    console.log(`Advertisement.get(${id})`);
    const user = await Auth.currentAuthenticatedUser();

    console.log(user);

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/advertisements/${id}`,
      config
    );

    const json = response.data.data as AdvertisementJson;

    return new Advertisement(
      json.id,
      json.status,
      json.title,
      json.video_url,
      json.thumbnail_url,
      json.transcoded_video_url,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.ages,
      json.view_count,
      json.view_seconds,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async search(
    keyword: string | null,
    last_evaluated_key: string | null = null,
    limit: number | null = null
  ): Promise<AdvertisementSearchResult> {
    console.log(
      `Advertisement.searchAppAdvertisement(${last_evaluated_key}, ${limit})`
    );
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
      params: {
        include_deleted: true,
        last_evaluated_key,
        limit,
      },
    };

    const response = await axios.get(
      `${process.env.VUE_APP_API_HOST}/advertisements`,
      config
    );

    const list: Advertisement[] = [];
    response.data.data.forEach((json: AdvertisementJson) => {
      list.push(
        new Advertisement(
          json.id,
          json.status,
          json.title,
          json.video_url,
          json.thumbnail_url,
          json.transcoded_video_url,
          json.available_from ? new Date(json.available_from) : null,
          json.available_to ? new Date(json.available_to) : null,
          json.ages,
          json.view_count,
          json.view_seconds,
          new Date(json.created_at),
          new Date(json.updated_at),
          json.deleted_at ? new Date(json.deleted_at) : null
        )
      );
    });

    return {
      advertisements: list,
      last_evaluated_key: response.data.last_evaluated_key,
    };
  }

  static async register(
    title: string,
    video_url: string,
    available_from: Date | null,
    available_to: Date | null,
    ages: string[]
  ): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.post(
      `${process.env.VUE_APP_API_HOST}/advertisements`,
      {
        title,
        video_url,
        available_from: available_from
          ? moment(available_from).format("YYYY-MM-DD")
          : null,
        available_to: available_to
          ? moment(available_to).format("YYYY-MM-DD")
          : null,
        ages: ages,
      },
      config
    );

    console.log(response);

    const json = response.data as AdvertisementJson;

    return new Advertisement(
      json.id,
      json.status,
      json.title,
      json.video_url,
      json.thumbnail_url,
      json.transcoded_video_url,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.ages,
      json.view_count,
      json.view_seconds,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async update(
    id: string,
    title: string,
    video_url: string,
    available_from: Date | null,
    available_to: Date | null,
    ages: string[]
  ): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.put(
      `${process.env.VUE_APP_API_HOST}/advertisements/${id}`,
      {
        id,
        title,
        video_url,
        available_from: available_from
          ? moment(available_from).format("YYYY-MM-DD")
          : null,
        available_to: available_to
          ? moment(available_to).format("YYYY-MM-DD")
          : null,
        ages: ages,
      },
      config
    );

    console.log(response);

    const json = response.data as AdvertisementJson;

    return new Advertisement(
      json.id,
      json.status,
      json.title,
      json.video_url,
      json.thumbnail_url,
      json.transcoded_video_url,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.ages,
      json.view_count,
      json.view_seconds,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }

  static async delete(id: string): Promise<Advertisement> {
    const user = await Auth.currentAuthenticatedUser();

    const config = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}}`,
      },
    };

    const response = await axios.delete(
      `${process.env.VUE_APP_API_HOST}/advertisements/${id}`,
      config
    );

    console.log(response);

    const json = response.data as AdvertisementJson;

    return new Advertisement(
      json.id,
      json.status,
      json.title,
      json.video_url,
      json.thumbnail_url,
      json.transcoded_video_url,
      json.available_from ? new Date(json.available_from) : null,
      json.available_to ? new Date(json.available_to) : null,
      json.ages,
      json.view_count,
      json.view_seconds,
      new Date(json.created_at),
      new Date(json.updated_at),
      json.deleted_at ? new Date(json.deleted_at) : null
    );
  }
}
