
import { defineComponent } from "vue";
import moment from "moment";
import Advertisement from "@/models/Advertisement";

interface AppAdvertisementListState {
  is_loading: boolean;
  keyword: string;
  advertisements: Advertisement[];
  last_evaluated_key: string | null;

  is_confirming: boolean;
  deletion_target: Advertisement | null;
}

const PAGE_SIZE = 20;

export default defineComponent({
  data() {
    return {
      is_loading: false,
      keyword: "",
      advertisements: [],
      last_evaluated_key: null,

      is_confirming: false,
      deletion_target: null,
    } as AppAdvertisementListState;
  },

  mounted() {
    this.refresh();
  },

  methods: {
    formatDate(date: Date, format: string) {
      return moment(date).format(format);
    },

    refresh() {
      console.log(`try to search (keyword=${this.keyword})`);

      if (this.is_loading) {
        return;
      }

      this.is_loading = true;
      setTimeout(async () => {
        try {
          const result = await Advertisement.search(
            this.keyword,
            this.last_evaluated_key,
            PAGE_SIZE
          );
          this.advertisements = result.advertisements;
          this.last_evaluated_key = result.last_evaluated_key;
        } finally {
          this.is_loading = false;
        }
      });
    },

    onConfirmDelete(advertisement: Advertisement) {
      this.deletion_target = advertisement;
      this.is_confirming = true;
    },

    cancelDeletion() {
      this.is_confirming = false;
      this.deletion_target = null;
    },

    onDelete() {
      if (this.is_loading) {
        return;
      }

      this.is_loading = true;
      setTimeout(async () => {
        if (!this.deletion_target) {
          return;
        }
        try {
          await Advertisement.delete(this.deletion_target.id);
        } finally {
          this.is_loading = false;
        }

        this.deletion_target = null;
        this.is_confirming = false;
        this.refresh();
      });
    },
  },
});
