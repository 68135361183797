
import { defineComponent } from "vue";
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

export default defineComponent({
  components: {
    Authenticator,
  },

  computed: {
    config() {
      return {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      };
    },
  },
});
