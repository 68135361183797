
import { defineComponent } from "vue";
import { Storage } from "aws-amplify";
import { uuid } from "vue-uuid";
import moment from "moment";
// import Editor from "@tinymce/tinymce-vue";

import Advertisement from "@/models/Advertisement";

interface AgeRange {
  code: string;
  name: string;
  is_selected: boolean;
}

interface AdvertisementEditState {
  is_loading: boolean;

  title: string;

  available_from: string;
  available_to: string;

  ages: AgeRange[];

  file: File | null;
  videoSource: string;
  filename: string | null;

  mceCallback: (uri: string, option: any) => void;
}

export default defineComponent({
  components: {
    // Editor,
  },

  data() {
    return {
      is_loading: false,

      title: "",

      available_from: "",
      available_to: "",

      ages: [
        { code: "0-19", name: "0 ~ 19歳", is_selected: false },
        { code: "20-29", name: "20代", is_selected: false },
        { code: "30-29", name: "30代", is_selected: false },
        { code: "40-29", name: "40代", is_selected: false },
        { code: "50-29", name: "50代", is_selected: false },
        { code: "60-", name: "60歳 ~", is_selected: false },
      ],

      file: null,
      videoSource: "",
      filename: null,
    } as AdvertisementEditState;
  },

  methods: {
    onFilePicked(callback: (uri: string, option: any) => void) {
      console.log(arguments);

      this.mceCallback = callback;
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;
      uploadFile.click();
    },

    async onUploadFileChanged() {
      const uploadFile = this.$refs.uploadFile as HTMLInputElement;

      if (!uploadFile.files) {
        return;
      }

      const readFileAsDataURL = (file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (evt: any) => {
            resolve(evt.target.result);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };

      this.file = uploadFile.files[0];

      const data = (await readFileAsDataURL(this.file)) as string;

      console.log(data);

      const video = this.$refs.video as HTMLVideoElement;

      this.videoSource = data;
      video.load();
    },

    onFilesDropped(e: DragEvent) {
      console.log("onFilesDropped");
      if (!e.dataTransfer) {
        return;
      }

      console.log(e.dataTransfer.files);

      const uploadFile = this.$refs.uploadFile as HTMLInputElement;
      uploadFile.files = e.dataTransfer.files;

      this.onUploadFileChanged();
    },

    clearVideo() {
      this.file = null;
      this.videoSource = "";
    },

    onSave() {
      if (this.is_loading) {
        return;
      }

      this.is_loading = true;
      setTimeout(async () => {
        try {
          if (!this.file) {
            return;
          }

          const split = this.file.name.split(".");
          const extension = split[split.length - 1];
          const filename = `${uuid.v4()}.${extension}`;
          Storage.configure({ customPrefix: { public: "advertisements" } });
          await Storage.put(`/${filename}`, this.file, {});

          const ages: string[] = [];

          this.ages.forEach((age) => {
            if (age.is_selected) {
              ages.push(age.code);
            }
          });

          await Advertisement.register(
            this.title,
            `${process.env.VUE_APP_HOST}/advertisements/${filename}`,
            this.available_from ? moment(this.available_from).toDate() : null,
            this.available_to ? moment(this.available_to).toDate() : null,
            ages
          );

          this.$router.push(`/advertisements`);
        } finally {
          this.is_loading = false;
        }
      });
      console.log(this);
    },
  },
});
