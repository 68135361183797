import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen bg-gray-200 font-roboto" }
const _hoisted_2 = { class: "flex-1 flex flex-col overflow-hidden" }
const _hoisted_3 = { class: "flex-1 overflow-x-hidden overflow-y-auto bg-gray-200" }
const _hoisted_4 = { class: "h-full container mx-auto p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SystemSidebar = _resolveComponent("SystemSidebar")!
  const _component_SystemHeader = _resolveComponent("SystemHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SystemSidebar, {
      is_sidebar_open: _ctx.is_sidebar_open,
      "onSidebar:toggle": _ctx.toggleSidebarOpen,
      onSignout: _ctx.signout
    }, null, 8, ["is_sidebar_open", "onSidebar:toggle", "onSignout"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SystemHeader, {
        is_sidebar_open: _ctx.is_sidebar_open,
        "onSidebar:toggle": _ctx.toggleSidebarOpen
      }, null, 8, ["is_sidebar_open", "onSidebar:toggle"]),
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}