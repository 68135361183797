import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Auth, Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";

import store from "@/store";

import Signin from "@/pages/Signin.vue";

import Home from "@/pages/Home.vue";
import AdvertisementList from "@/pages/AdvertisementList.vue";
import AdvertisementDetail from "@/pages/AdvertisementDetail.vue";
import AdvertisementEdit from "@/pages/AdvertisementEdit.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/advertisements",
    name: "AdvertisermentList",
    component: AdvertisementList,
  },
  {
    path: "/advertisements/:advertisement_id",
    name: "AdvertisementDetail",
    component: AdvertisementDetail,
  },
  {
    path: "/advertisements/:type/edit",
    name: "AdvertisementEdit",
    component: AdvertisementEdit,
  },

  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    meta: { context: "auth" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const userInfo = await Auth.currentUserInfo();

  if (!userInfo && to.fullPath !== "/signin") {
    next("/signin");
    return;
  }

  if (userInfo && to.fullPath == "/signin") {
    next("/");
    return;
  }

  next();
});

const authListener: HubCallback = ({ payload: { event /*, data */ } }) => {
  console.log(event);
  switch (event) {
    case "signIn":
      // setUser({
      //   username: data.username,
      //   token: data.signInUserSession.accessToken,
      // });
      store.dispatch("signIn");
      router.replace("/");
      break;
    case "signOut":
      router.replace("/signin");
      break;
  }
};

Hub.listen("auth", authListener);

export default router;
