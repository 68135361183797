import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-2 mt-2 lg:mt-0 rounded shadow bg-white" }
const _hoisted_2 = { class: "p-6" }
const _hoisted_3 = { class: "text-xl font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-start"
}
const _hoisted_5 = {
  key: 1,
  class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-start"
}
const _hoisted_6 = { class: "pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-start" }
const _hoisted_7 = { class: "p-2 mt-2 lg:mt-0 rounded shadow bg-white" }
const _hoisted_8 = { class: "video-docker w-full overflow-hidden" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.advertisement.title), 1),
        (_ctx.advertisement.available_from || _ctx.advertisement.available_to)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("span", null, [
                _createVNode(_component_fa, {
                  icon: "calendar",
                  class: "text-sm text-color-3 mr-2"
                })
              ]),
              _createTextVNode(" " + _toDisplayString(`${
            _ctx.advertisement.available_from
              ? _ctx.formatDate(_ctx.advertisement.available_from, "YYYY-MM-DD")
              : "-"
          } ~ ${
            _ctx.advertisement.available_to
              ? _ctx.formatDate(_ctx.advertisement.available_to, "YYYY-MM-DD")
              : "-"
          }`), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.advertisement.ages)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _createElementVNode("span", null, [
                _createVNode(_component_fa, {
                  icon: "user",
                  class: "text-sm text-color-3 mr-2"
                })
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advertisement.ages, (age) => {
                return (_openBlock(), _createElementBlock("span", { key: age }, _toDisplayString(_ctx.formatAge(age)) + " ", 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_6, [
          _createElementVNode("span", null, [
            _createVNode(_component_fa, {
              icon: "eye",
              class: "text-sm text-color-3 mr-2"
            })
          ]),
          _createTextVNode(" " + _toDisplayString(`${_ctx.advertisement.view_count}`), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("video", {
          src: _ctx.advertisement.video_url,
          autoplay: "true",
          controls: ""
        }, null, 8, _hoisted_9)
      ])
    ])
  ], 64))
}