
import { defineComponent } from "vue";

interface SidebarState {
  activeClass: string;
  inactiveClass: string;
}

export default defineComponent({
  name: "Sidebar",

  props: {
    is_sidebar_open: Boolean,
  },

  data() {
    return {
      activeClass: "bg-gray-600 bg-opacity-25 text-gray-100 border-gray-100",
      inactiveClass:
        "border-gray-900 text-gray-500 hover:bg-gray-600 hover:bg-opacity-25 hover:text-gray-100",
    } as SidebarState;
  },

  methods: {
    toggleSidebarOpen() {
      this.$emit("togglesidebar");
    },

    signout() {
      this.$emit("signout");
    },
  },
});
